/* eslint-disable no-console */

import { loadRegisteredModule } from "@ithaka/federated-module-loader";
import Cookie from "js-cookie";

/**
 * This function mounts all the MFEs that global.js is typically responsible for.
 * It is a proof of concept to see if there is a noticeable improvement to mounting all MFEs from a single entry point.
 */
export function mountGlobalMfes() {
    console.log("mounting global MFEs on the homepage...");

    loadRegisteredModule("mfeLogger/loggingDispatcher").then(({ initialize }) => { initialize(); });

    const headerMountPoint = document.getElementById("headerMountPoint");
    if (headerMountPoint) {
        loadRegisteredModule("mfeHeader/MfeHeader")
            .then((module) => {
                module.mount(headerMountPoint, { uuid: Cookie.get("UUID"), skipToId: "content" });
            });
    }

    const unsupportedBrowserMessageMountPoint = document.getElementById("unsupportedBrowserMessageMountPoint");
    if (unsupportedBrowserMessageMountPoint) {
        loadRegisteredModule("mfeUnsupportedBrowserMessage/MfeUnsupportedBrowserMessage").then((module) => {
            module.mount(unsupportedBrowserMessageMountPoint);
        });
    }

    const ssoRegistrationMountPoint = document.getElementById("ssoRegistrationMountPoint");
    if (ssoRegistrationMountPoint) {
        loadRegisteredModule("mfeSsoRegistration/MfeSsoRegistration")
            .then((module) => {
                module.mount(ssoRegistrationMountPoint);
            });
    }

    const registeredFooterMountPoint = document.getElementById("registeredFooterMountPoint");
    if (registeredFooterMountPoint) {
        loadRegisteredModule("mfeFooter/MfeFooter").then((module) => { module.mount(registeredFooterMountPoint); });
    }

    const statusBannerMountPoint = document.getElementById("statusBannerMountPoint");
    if (statusBannerMountPoint) {
        const env = window.location.hostname.match(/^(firefly.jstor.org|c20n-.*\.apps\.test\.cirrostratus\.org|localhost(:.*)?)$/) ? "test" : "prod";
        const component = env === "test" ? "n1nps25swpk9" : "lcl8nmkfysby";
        loadRegisteredModule("mfeStatusBanner/MfeStatusBanner")
            .then((module) => {
                module.mount(statusBannerMountPoint, component);
            });
    }

    const accessWorkflowMountPoint = document.getElementById("globalAccessWorkflowMountPoint");
    if (accessWorkflowMountPoint) {
        loadRegisteredModule("mfeAccessWorkflow/MfeAccessWorkflow").then((module) => {
            module.mountModal(accessWorkflowMountPoint, { });
        });
    }
}